import React from "react"
import { connect } from "react-redux"
import SBheader from "../components/SBheader"
import SBnewFooter from "../components/SBnewFooter"
import { getUserPreferredName, getCmsGreetingMessage, getLocale, getUserCardsList } from "../helpers/stateFunctions"
import * as appActions from "../redux/app"
import * as authActions from "../redux/auth"
import { Button } from '../components/button'
import Router from 'next/router'
import { Frap } from '../components/button'
import _ from 'lodash'
import { TEXTS } from "../helpers/statics"
import loadFirebase from "../lib/db"
import RBS, { RbsRegion } from "@rettersoft/rbs-sdk"
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
import { isProd } from '../helpers/utility'
import { getMainVideoUrl } from "../helpers/rioHelper"
class Main extends React.Component {
    state = {
        contents: [],
        mainVideoUrl: "",
    }

    async componentDidMount() {
        this.getRbsCmsContent()
        const firebase = await loadFirebase()
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setTimeout(function () {
                    const meters = document.querySelectorAll("svg[data-value] .meter-rewards")
                    meters.forEach(path => {
                        let length = path.getTotalLength()
                        let value = parseInt(path.parentNode.getAttribute("data-value"))
                        let to = length * ((100 - value) / 100)
                        path.getBoundingClientRect()
                        path.style.strokeDashoffset = Math.max(0, to)
                    })
                }, 500)
            }
        })
        try {
            let resp = await getMainVideoUrl()
            let mainVideoUrl = resp?.data?.result
            this.setState({ mainVideoUrl })
        }
        catch {

        }
    }

    async getRbsCmsContent() {
        let userLocale = getLocale(this.props) == "tr" ? "tr_TR" : "en_US"
        //console.log("userLocale is",userLocale)
        let rbs = RBS.getInstance({
            projectId: publicRuntimeConfig.RBS_PROJECT_ID,
            region: isProd() ? RbsRegion.euWest1 : RbsRegion.euWest1Beta
        }, true)
        const formResponse = await rbs.send({
            action: 'rbs.cms.get.CONTENT',
            data: {
                id: "9ea51806-00c4-4220-ae40-b1a841b09d90",
                cacheForDutation: 0,
            },
        })
        let response = formResponse[0]?.response
        //console.log("response is",response)
        let contents = []
        response.children.forEach(c => {
            delete c.getRelatedItems
            let content = {
                title: "",
                description: "",
                primary_button: "",
                primary_button_link: "",
                secondary_button: "",
                secondary_button_link: "",
            }
            content.id = c.id
            content.imageId = c.images && c.images[0] && c.images[0].id
            content.content_type = c.attributes.find(t => t.type == "content_type").value[0].value
            content.title = c.attributes.find(t => t.type == "title").value.find(v => v.locale == userLocale).value
            content.description = c.attributes.find(t => t.type == "description").value.find(v => v.locale == userLocale).value
            content.primary_button = c.attributes.find(t => t.type == "primary_button").value.find(v => v.locale == userLocale).value
            content.primary_button_link = c.attributes.find(t => t.type == "primary_button_link").value.find(v => v.locale == userLocale).value
            content.secondary_button = c.attributes.find(t => t.type == "secondary_button").value.find(v => v.locale == userLocale).value
            content.secondary_button_link = c.attributes.find(t => t.type == "secondary_button_link").value.find(v => v.locale == userLocale).value
            contents.push(content)
            if (content.imageId) {
                this.getImageURL(content.imageId)
            }
        })
        //console.log("contents is",contents)
        this.setState({ contents })
    }

    async getImageURL(imageId) {
        const projectId = publicRuntimeConfig.RBS_PROJECT_ID
        const region = isProd() ? RbsRegion.euWest1 : RbsRegion.euWest1Beta
        const rbs = RBS.getInstance({
            projectId,
            region,
            anonymTokenTTL: 10000
        })
        const url = await rbs.generateGetActionUrl({
            action: 'rbs.storage.get.GET_IMAGE',
            data: {
                imageId,
                fit: 'cover',
            },
        })
        //console.log("imageId is",imageId)
        //console.log("image url is",url)
        this.setState({ [imageId]: url })
    }

    render() {
        let _state = this.props
        return (
            <div>

                <SBheader />

                <main id="content" className="homeCapsulv2 height-100 flex flex-column" role="main">
                    {this.state.mainVideoUrl &&
                        <div className="sb-headerCrate flex flex-column bg-white  flex-shrink-none">
                            <div className="flex flex-grow flex-column ">
                                <video className="sb-card__image" style={{ verticalAlign: "baseline", backgroundColor: "#dcf0e3" }} height={600} autoPlay="autoplay" loop="loop" muted defaultmuted="true" playsInline preload="auto">
                                    <source src={this.state.mainVideoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <h1 className="sb-heading sb-heading--large" style={{ position: "absolute", bottom: "40px", left: "40px" }} >{getCmsGreetingMessage(_state).messageText.replace("{NAME}", getUserPreferredName(_state))}</h1>
                            </div>
                        </div>}

                    <div className="sb-contentCrate flex-grow">
                        <div className="sb-content-capsul relative flex-auto">
                            <div className="flex-auto">
                                <div>
                                    <div className="main-v2">

                                        <div className="campaign-list">
                                            {this.state.contents.map((c, i) => {
                                                if (c.content_type == "img_left_text_right") {
                                                    return (
                                                        <div key={i} className="campaign-item">
                                                            <div className="left">
                                                                <img src={this.state[c.imageId]} />
                                                            </div>
                                                            <div className="right">
                                                                <span className="title">{c.title}</span>
                                                                <p className="text">{c.description}</p>
                                                                <div className="campaign-action">

                                                                    {c.primary_button &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (c.primary_button_link.indexOf("www") < 0) {
                                                                                    Router.push(c.primary_button_link)
                                                                                } else {
                                                                                    window.open(c.primary_button_link)
                                                                                }
                                                                            }}
                                                                            className="mr3" visualStyle="positive" type="button">
                                                                            {c.primary_button}
                                                                        </Button>}

                                                                    {c.secondary_button &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (c.secondary_button_link.indexOf("www") < 0) {
                                                                                    Router.push(c.secondary_button_link)
                                                                                } else {
                                                                                    window.open(c.secondary_button_link)
                                                                                }
                                                                            }}
                                                                            className="mr3" type="button">
                                                                            {c.secondary_button}
                                                                        </Button>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (c.content_type == "img_up_text_down") {
                                                    return (
                                                        <div key={i} className="top-banner">
                                                            <div className="top-banner-cover">
                                                                <img src={this.state[c.imageId]} />
                                                            </div>

                                                            <div className="top-banner-detail">
                                                                <h3 className="title">{c.title}</h3>
                                                                <p className="text">{c.description}</p>
                                                                <div className="banner-action">

                                                                    {c.primary_button &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (c.primary_button_link.indexOf("www") < 0) {
                                                                                    Router.push(c.primary_button_link)
                                                                                } else {
                                                                                    window.open(c.primary_button_link)
                                                                                }
                                                                            }}
                                                                            className="mr3" visualStyle="positive" type="button">
                                                                            {c.primary_button}
                                                                        </Button>}

                                                                    {c.secondary_button &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (c.secondary_button_link.indexOf("www") < 0) {
                                                                                    Router.push(c.secondary_button_link)
                                                                                } else {
                                                                                    window.open(c.secondary_button_link)
                                                                                }
                                                                            }}
                                                                            className="mr3" type="button">
                                                                            {c.secondary_button}
                                                                        </Button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>

                                    </div>

                                    <div className="container my8 text-center"></div>
                                    <hr aria-hidden="true" className="sb-rule sb-rule--thin mb4 lg-mb6" />

                                    <SBnewFooter />
                                </div>
                            </div>
                        </div>
                    </div>

                </main>

                {getUserCardsList(_state).length >= 1 &&
                    <div className="sticky-pay-btn">
                        <span className="balance-sum" style={{ backgroundColor: 'rgb(255 255 255)', color: 'black', padding: '5px 10px', borderRadius: '20px', fontSize: '12px', border: '1px solid #1f3832', position: 'absolute', top: '-16px', right: '0', zIndex: 9 }}><b>{getUserCardsList(this.props)[0] ? getUserCardsList(this.props)[0].balance.toFixed(2) : "-ll"}₺</b></span>
                        <Frap loading={""} className='mr3 mb4' disabled={""} onClick={() => {
                            Router.push("/starbuckscard?section=addMoney", "/starbuckscard/addMoney").then(() => { window.scrollTo(0, 0) })
                        }}>{TEXTS.add_money[getLocale(_state)]}
                        </Frap>
                    </div>}

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //APP//
        appValueChanged: (k, v) => { dispatch(appActions.appValueChanged(k, v)) },

        //AUTH//
        authValueChanged: (k, v) => { dispatch(authActions.valueChanged(k, v)) },
        updateProfile: (params, callback) => {
            dispatch(authActions.updateProfile(params)).then(resp => {
                if (callback) {
                    callback(resp)
                }
            })
        },
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)
